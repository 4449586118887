@import "variables";
@import "mixins";

.master-color {
  color: $master-text-color;
}

.rainbow {
  @include rainbow;
}

.selectable {
  @include selectable;
}

.deselectable {
  @include deselectable;
}

.input-clearer {
  display: inline-block;
  width: $input-height;
  height: $input-height;
  line-height: $input-height;
  margin-right: -$input-height;
  position: relative;
  left: -$input-height;
  color: $light-gray-text;
  text-align: center;
  cursor: pointer;

  &:hover {
    font-weight: bold;
    color: $master-color;
  }

  &:active {
    color: $master-dark-color;
  }
}

.inactive {
  color: $light-gray-text;
}
