$font-size: 16px;
$line-height: 1.25;

$h1-font-size: 48px;
$h2-font-size: 36px;
$h3-font-size: 24px;
$h4-font-size: 20px;

$body-padding: 5px;
$horizontal-padding: 2px;
$vertical-padding: 14px;
$input-height: 30px;
$tab-height: 50px;
$tab-width: 150px;
$content-width: 910px;

$faded-opacity: 0.55;
$faded-hover-opacity: 0.68;
$faded-active-opacity: 0.85;

$red: #ff0066;//rgb(255, 106, 97);
$yellow: rgb(255, 212, 113);
$green: rgb(148, 228, 109);
$bright-green: #86e010; //rgb(134,224,16)
$blue: #00B4D5;//#174B9E; //rgb(110, 210, 239);
$gold: #ffcc00; //#ef9d0e; //rgb(255,204,0);
$teal: #00ccff;//rgb(0,200,210); //#00c8d2
$violet: rgb(208, 146, 244);

//#ffcc00 #00ccff #ff0066

$dark-red: darken($red, 20%);
$dark-yellow: darken($yellow, 20%);
$dark-green: darken($green, 20%);
$dark-blue: darken($blue, 20%);
$dark-gold: darken($gold, 50%);
$dark-violet: darken($violet, 20%);

$rainbow: $red mix($red, $yellow) $yellow mix($yellow, $green) $green
  mix($green, $blue) $blue mix($blue, $violet) $violet mix($violet, $red);

$black-text: #000000;
$black-background: #000000;
$white-text: #efffff;
$bright-white-text: #efffff;
$bright-green-text: #86e010;
$light-gray-text: #bbb;

$master-color: $gold;
$master-dark-color: $dark-green;
$master-text-color: $blue;
$master-text-dark-color: saturate(darken($master-color, 42%), 30%);

$highlight-border-color: $master-color;
$highlight-color: transparentize($highlight-border-color, 0.75);
$highlight-hover-color: transparentize($highlight-border-color, 0.9);
$highlight-hover-border-color: transparentize($highlight-border-color, 0.4);
$highlight-active-color: transparentize($highlight-border-color, 0.83);
$highlight-active-border-color: transparentize($highlight-border-color, 0.2);

$border-radius: 2px;
$border-color: #bbb;
$border-color-active: darken($border-color, 10%);
$border-color-master: darken($master-color, 20%);
