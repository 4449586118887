@import "compass/reset";
@import "compass/css3";
@import "compass/typography";
@import "compass/css3/box-sizing";
@import "compass/css3/box-shadow";
@import "compass/utilities";
@import "variables";
@import "mixins";
@import "classes";

* {
  @include box-sizing(border-box);
}

.ng-hide {
  display:none!important;
}

/* View transitions */
main.ng-enter {
  transition: all 0.6s linear .6s;
  opacity:0;
  transform: translateY(-160px)
}
main.ng-enter.ng-enter-active {
  opacity:1;
  transform: translateY(0)
}
main.ng-leave {
  transition:all 0.6s linear;
  opacity:1;
  transform: translateX(0)
}
main.ng-leave.ng-leave-active {
  opacity:0;
  transform: translateX(140px)
}

.flash {
   animation-name: flash;
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-play-state: running;
}

@keyframes flash {
    from {color: red;}
    to {color: black;}
}

.pulsate {
    -webkit-animation: pulsate 4s ease-out;
    -webkit-animation-iteration-count: infinite;
    opacity: 0.6;
    color: $blue;
}

.pulsate:hover {
    color:$master-color;
  }

@-webkit-keyframes pulsate {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.5;
    }
}

.parallax {
    background-image:"G:/Drive/Sol Sustenance/3solvera/images/wood_wallpaper.jpg";
    height:100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

body {
  height: 100%;
  padding: $body-padding;
  font-size: $font-size;
  line-height: $line-height;
  font-family: sans-serif;
  color: $white-text;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: $black-background;

}
div.cblock{
  position:relative;
  left: 200px;
}
h1 {
  font-size: $h1-font-size;
}
h2 {
  padding: 5px;
  padding-bottom:15px;
  font-size: $h2-font-size;
}
h3 {
  font-size: $h3-font-size;
  padding-left: 20px;
  padding-top: 10px;
  text-shadow: 2px $gold;

}

p {
  padding-left:20px;
  padding-top:10px;
  padding-right:15px;
  font-size: $font-size;
  line-height: 1.75em;
  &single_space {
    line-height:.8em;
    padding-top:0px;
    padding-bottom:0px;
  }
  }



input[type="text"] {
  height: $input-height;
  font-size: $font-size;
  padding: 6px 6px;
  margin: 0;
  border-radius: $border-radius;
  @include border;
  outline: white;
  width: 300px;
  background-color: black;

  &:hover {
    border-color: $border-color-active;
  }

  &:focus {
    border-color: $border-color-master;
  }
}

.box{
    display: none;
    width: 100%;
}

a:hover + .box,.box:hover{
    display: block;
    position: relative;
    z-index: 100;
}

.floated{
    float: left;
    padding-top: 15px;
    padding-bottom: 5px;
    padding-right:20px;
    .right{
      padding-left:20px;
    }
}

.amazon-iframe{
  width:120px;
  height:240px;
}

input[type="checkbox"] {
  position: relative;
  top: 1px;
}

button {
  font-size: $font-size;
  cursor: pointer;
  padding: 6px 8px;
  margin: 0;
  border-radius: $border-radius;
  @include border;
  @include background-image(linear-gradient(#eee, #fff 15%, #eee));
  color: #444;

  &:hover {
    border-color: $border-color-active;
    @include box-shadow(0 1px 4px rgba(0, 0, 0, 0.1));
    @include background-image(linear-gradient(#e7e7e7, #fff 15%, #e7e7e7));
  }

  &:active {
    @include box-shadow(0 1px 4px rgba(0, 0, 0, 0.08) inset, 0 0 8px 1px rgba(0, 0, 0, 0.1) inset);
  }
}

// Pseudo classes don't support a comma between selectors, and -moz is still required for Firefox
::selection {
  background: $master-color;
}
::-moz-selection {
  background: $master-color;
}


.menu {
  padding-top:10px;
  float:right;

  li {
    display: inline-block;
    height: $tab-height;
    text-align: right;
    padding-right: 8px !important;
    padding-left: 20px;

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      float:right;
      line-height: $tab-height;
      text-align: right;
      color: $master-text-color;
      text-decoration: none;
      font-size: $h3-font-size;
      color:$master-color;
      opacity: $faded-opacity;
      border-bottom: 2px dashed transparent;

      &:hover {
        border-bottom-color: $master-text-color;
        opacity: $faded-hover-opacity;
      }

      &:active {
        opacity: $faded-active-opacity;
      }
    }

    &.active {
      a {
        border-bottomcolor: $master-text-color;
        opacity: 1.0;
      }
    }
  }
}

.tabs {
  li {
    display: inline-block;
    width: $tab-width;
    height: $tab-height;

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      line-height: $tab-height;
      text-align: center;
      color: $white-text;
      text-decoration: none;
      font-size: $h3-font-size;
      opacity: $faded-opacity;
      border-bottom: 2px dashed transparent;

      &:hover {
        border-bottom-color: $white-text;
        opacity: $faded-hover-opacity;
      }

      &:active {
        opacity: $faded-active-opacity;
      }
    }

    &.active {
      a {
        border-bottom-color: $white-text;
        opacity: 1.0;
      }
    }
  }
}

a {
  color: $master-text-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  &:visited {
    color: $master-color;
    text-decoration:line-through;
}
}
