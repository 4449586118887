@mixin border($color: $border-color) {
  border: 1px solid $color;
}

@mixin rainbow {
  $length: length($rainbow);
  $i: 1;
  @while $i <= $length {
    :nth-child(#{$length}n + #{$i}) {
      background-color: nth($rainbow, $i);
    }
    $i: $i + 1;
  }
}

@mixin selectable-hover {
  background-color: $highlight-hover-color;
  border-color: $highlight-hover-border-color;
}

@mixin selectable-selected {
  background-color: $highlight-color;
  border-color: $highlight-border-color;
}

@mixin selectable-active {
  background-color: $highlight-active-color;
  border-color: $highlight-active-border-color;
}

@mixin selectable {
  cursor: pointer;
  border: 2px dashed transparent;
  border-radius: $border-radius;

  &:hover {
    @include selectable-hover;
  }

  &.selected {
    cursor: default;
    @include selectable-selected;
  }

  &:active {
    @include selectable-active;
  }
}

@mixin deselectable {
  &.selected {
    cursor: pointer;
  }
}

@mixin drop-shadow {
  @include box-shadow(0 2px 14px 1px rgba(0, 0, 0, 0.15));
}

@mixin dropdown($top-offset: 0px) {
  position: relative;

  .dropdown-toggle {
    padding: 5px $horizontal-padding;
    cursor: pointer;
    @include selectable;
    position: relative;
    top: $top-offset;
  }

  &.open {
    .dropdown-toggle {
      @include selectable-selected;

      &:active {
        @include selectable-active;
      }
    }
  }

  .dropdown-content {
    position: absolute;
    top: $input-height + $top-offset;
    left: 0;
    display: none;
    z-index: 10;
    background-color: black;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    @include drop-shadow;
    padding: $horizontal-padding 0;

    > li {
      padding: 3px $horizontal-padding;
    }

    &.dropdown-content-right {
      left: auto;
      right: 0;
    }

    &.dropdown-content-left {
      left: 0;
      right: auto;
    }
  }

  &.open {
    .dropdown-content {
      display: block;
    }
  }
}
