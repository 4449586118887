@import "./sassy/main";

[ng-cloak] {
  display: none;
}

#rectangle{
    width:905px;
    height:130px;
    margin: 0 auto;
    padding:0px;
    background:$black-background;
    position:relative;
    bottom:135px;
    display: block;
}


.content_button {
    border: none;
    background: $black-background;
    color: $master-text-color;
    font-size: $h2-font-size;
    position: relative;
    bottom: 2px;
    cursor: pointer;
}

.content_button:hover {
    color:$master-color;
    background:$black-background;
}


.inline_content_button {
    border: none;
    background: $black-background;
    color: $master-text-color;
    cursor: pointer;
}

.inline_content_button:hover {
    color:$master-color;
    background:$black-background;
}

.left{
  float:left;
  padding-left:10px;
}

.centering{
  align:center;
}

.right{
  float:right;
  padding-right:10px;
}

#wrapper {
  position: relative;
}

#header-container {
  white-space: nowrap;
  padding-left: $horizontal-padding;
  text-align: left;
  width: $content-width;
  display: block;
  margin: 0 auto;
}

#main-container{
  background-color: black;
  padding-left:10px;
  min-height: 1000px;
  width:920px;
  margin: 0 auto;
  box-shadow: 0px 0px 40px 6px $dark-gold;
  /* x offset, y offset, blur, spread*/

}
#footer-container {
  position: relative;
  top:20px;
  height:80px;
  width: 100%;
  white-space: nowrap;
  padding-top: $body-padding;
  padding-left: $horizontal-padding;
  text-align: center;
  display: block;
  margin: 0 auto;
}

#content{
  background: black;
  opacity:.8;
  display: block;
  width: $content-width;
  margin: 0 auto;
  text-align: left;
}

#title {
  display: inline-block;
  padding-left:20px;
  padding-top:6px;
  color: $master-color;
}

#nav-container {
  display: inline-block;
  float:right;
}

#loading-message {
  width: $tab-width;
  height: $tab-height;
  line-height: $tab-height;
  border-bottom: 2px dashed $white-text;
  opacity: $faded-opacity;
  text-align: center;
}


.ng-invalid{
  input{
    border: 1px solid red;}
  }

.food-list {
  display: block;
  text-align: left;
  width: $content-width;

  li {
    padding: 2px $horizontal-padding;

    .food-label {
      width: 100%;
      color: $white-text;
    }

    .food-group {
      float: right;
    }
  }
}

.nutrient-list {
  li {
    padding: 1px $horizontal-padding;
  }
}

.search-form {
  width: $content-width;
  padding: $horizontal-padding  $horizontal-padding  $horizontal-padding ;
  white-space: nowrap;
  position: relative;
}

$comparison-list-width: 220px;
$comparison-header-height: 130px; // comparisonHeaderHeight in visuals.coffee
$comparison-row-height: 45px; // comparisonRowHeight in visuals.coffee

food-comparison {
  position: relative;
  left: 100;
  h3 {
    margin-bottom: 2px;
  }

   div {
    position: relative;
  }
}

.food-comparison-list {
  width: $comparison-list-width;
  position: absolute;
  left: 0;
  top: $comparison-header-height;
  z-index: 1;

  li {
    position: relative;
    height: $comparison-row-height;
    padding-right: $horizontal-padding;
  }

  .food-label {
    height: 100%;
    width: 100%;
    display: table; // vertical centering hack for variable height children

    span {
      display: table-cell;
      vertical-align: middle;
      padding: 0 $horizontal-padding;
      color: $white-text;
      line-height: 1;
    }
  }
}

.remove-button {
  position: absolute;
  left: -20px;
  top: 14px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  padding-left: $horizontal-padding;
  color: $light-gray-text;

  &:hover {
    font-weight: bold;
    color: $master-color;
  }

  &:active {
    color: $master-dark-color;
  }
}

.food-comparison-graphs {
  white-space: nowrap;
  margin-left: $comparison-list-width + $horizontal-padding;

  svg {
    margin-left: -4px; // to prevent text disappearance problems

    &:first-child {
      position: relative;
      top: 3px;
    }
  }
}

.clear-selected-button {
  position: relative;
  top: 5px;
  margin-bottom: 10px;
  margin-left: $horizontal-padding;
}

.nutrient-label {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.text-with-icon {
  width: $content-width;
  $icon-size: 48px;
  position: relative;
  padding-left: $icon-size + $horizontal-padding * 2;
  min-height: $icon-size;
  margin-bottom: $font-size * 2;

  img {
    width: $icon-size;
    height: $icon-size;
    position: absolute;
    left: 0;
    top: 0;
  }
}

nutrient-list {
  display: block;
  margin-bottom: $vertical-padding;
}

.nutrient-graph {
  display: inline-block;
  width: 100px;
  height: 16px;
  background-color: transparentize($green, 0.5);
  position: relative;
  top: 3px;
}

.nutrient-bar {
  background-color: $gold;
  height: 16px;
}

food-detail {
  white-space: nowrap;
}

.food-detail-graph {
  margin: $vertical-padding 0;
  display: inline-block;
}

.food-detail-pie-chart-labels {
  display: inline-block;
  font-size: $h3-font-size;
  position: relative;
  top: -61px;
  left: 13px;
}

.fat {
  color: $red;
}

.carbohydrate {
  color: $yellow;
}

.protein {
  color: $blue;
}

.padded-left {
  padding-left: $horizontal-padding;
}

.padded {
  padding: $horizontal-padding;
}

food-group-filter {
  position: absolute !important;
  right: 0;
  top: $horizontal-padding;

  ul {
    li {
      position: relative;
      padding-right: 46px !important;

      span {
        position: absolute;
        right: $horizontal-padding;
        top: 6px;
      }
    }
  }
}


.mm-dropdown {
  @include dropdown(2px);
}

.db-dropdown {
  @include dropdown(2px);
}

#presets {
  width: 179px;
  margin-bottom: 8px;
  .dropdown-content {
    li {
      padding: 0 30px 0 $horizontal-padding;
      position: relative;
    }
    .selectable {
      padding: 3px $horizontal-padding;
    }
  }
  .dropdown-toggle {
    display: inline-block;
  }
  #save-preset {
    white-space: nowrap;
    margin-bottom: $horizontal-padding;
    padding-right: $horizontal-padding;
    button {
      margin-left: 2px;
    }
  }
  .remove-button {
    left: auto;
    right: 11px;
    top: 5px;
  }
}

#database-options {
  width: 200px;
  margin-bottom: 5px;
  .dropdown-content {
    li {
      padding: 100px 30px 0 $horizontal-padding;
      position: relative;
    }
    .selectable {
      padding: 3px $horizontal-padding;
    }
  }
  .options-toggle {
    display: inline-block;
  }
}

#nutrients {
  .food-list {
    .food-label {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

$column-width: 120px;
$overflow-column-width: 120px;
$num-columns: 8;

#nutrient-columns {
  position: relative;
  left:0px;
  display: block;
  min-height: 90%;
  width: 910px;
  margin: 0 auto;
  text-align: left;
  height: 370px;

}

.column {
  position: absolute;
  top: 0;
  width: $column-width;
  padding: 0 $horizontal-padding $horizontal-padding $horizontal-padding;

  $i: 1;
  @while $i <= $num-columns {
    &:nth-child(#{$i}) {
      left: ($i - 1) * $column-width;
    }
    $i: $i + 1;
  }

  &:last-child {
    width: $overflow-column-width;
  }
}

#database-list {
  width: $content-width;
}

/* Media Queries */

@media screen and (max-width: 700px){


}
